import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-location";
import { CurrencyFormatter } from "../common/currency";
import { CheckoutForm, DonationPageStateContext } from "../state";

import "./checkout-cart.css";

export const CheckoutCart = () => {
    const { state } = useContext(DonationPageStateContext);
    const { watch } = useFormContext<CheckoutForm>();
    const navigate = useNavigate()

    // Local state for showing/hiding recipients
    const [showRecipients, setShowRecipients] = useState(false);

    // Get the cart amounts and fee value from state
    const checkoutForm = watch();
    const coveredFeesEnabled = checkoutForm.donorCoveredFees.enabled;
    const coveredFeesAmount = state.cartAmounts.donorCoveredFees;
    const tipAmount = state.cartAmounts.tip;
    const mandatoryFeeAmount = state.cartAmounts.mandatoryFee;

    useEffect(() => {
        if (window.innerWidth >= 768) {
            setShowRecipients(true);
        }
    }, []);

    // Event handlers
    const navigateToSlate = () => {
        navigate({ to: "../", replace: true });
    }

    const toggleRecipients = () => {
        setShowRecipients(!showRecipients);
    }

    // Build elements
    const recipientListItems = state.recipients
        .filter(r => r.donationAmount && r.donationAmount.amount > 0)
        .map(r =>
            <li key={r.identifier}><span>{r.displayName}:</span> <span>{CurrencyFormatter.format(r.donationAmount.amount)}</span></li>
    );

    if (tipAmount) {
        recipientListItems.push(<li key="tip"><span>Tip:</span> <span>{CurrencyFormatter.format(tipAmount)}</span></li>);
    }

    if (coveredFeesEnabled && coveredFeesAmount) {
        recipientListItems.push(<li key="donover-covered-fees"><span>Processing Fees:</span> <span>{CurrencyFormatter.format(coveredFeesAmount)}</span></li>);
    }

    if (mandatoryFeeAmount) {
        recipientListItems.push(<li key="mandatory-fee"><span>{state.configuration.checkout.mandatoryFee.lineItemText}:</span> <span>{CurrencyFormatter.format(mandatoryFeeAmount)}</span></li>);
    }

    const header = <h1>{CurrencyFormatter.format(state.cartAmounts.total)}</h1>;

    const editButton = <span className="edit" onClick={() => navigateToSlate()}>Edit</span>;
    const toggleRecipientsButton = <span className="toggle-recipients" onClick={() => toggleRecipients()}>{showRecipients ? "Hide Details" : "Show Details"}</span>;

    const recurringNotification = state.configuration.slate.subscription.enabled
        ? (state.createSubscription ? <label className="subscription">This is a monthly donation</label> : <></>)
        : <></>

    return (
        <section className="checkout-cart" aria-label="Cart">
            {header}
            {showRecipients && <ul>
                {recipientListItems}
                </ul>
            }
            {recurringNotification}
            {editButton}
            {recipientListItems.length > 0 && toggleRecipientsButton}
        </section>
    );
}
