import React, { useContext } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DonationPageStateContext } from "../state";
import { ActionType } from "../actions";
import { SingleRecipientCard } from "./single-recipient-card";
import "./recipient-dialog.css";
import { ExtendedProfile } from "./extended-profile";

export const RecipientDialog = () => {
    const { state, dispatch } = useContext(DonationPageStateContext);
    const dialog = state.recipientDialog;

    const recipient = state.recipients.find((r) => r.identifier === dialog.recipientId);

    // Links
    const links = [];

    if (recipient?.links?.facebook) {
        links.push(<a href={recipient?.links.facebook} key="facebook" target="_blank" className="facebook"><span className="k-icon k-i-facebook" /> Facebook</a>);
    }

    if (recipient?.links?.twitter) {
        links.push(<a href={recipient?.links.twitter} key="twitter" target="_blank" className="twitter"><span className="k-icon k-i-twitter" /> Twitter</a>);
    }

    if (recipient?.links?.youTube) {
        links.push(<a href={recipient?.links.youTube} key="youtube" target="_blank" className="youtube"><span className="k-icon k-i-youtube" /> YouTube</a>);
    }

    if (recipient?.links?.site) {
        links.push(<a href={recipient?.links.site} key="site" target="_blank" className="site"><span className="k-icon k-i-globe" /> Web Site</a>);
    }

    return (<>{recipient && dialog.show &&
                <Dialog className="recipient-dialog" title=" " onClose={() => dispatch({type: ActionType.HideRecipientDialog})}>
                    <SingleRecipientCard recipient={recipient} showDescription={true} showDonation={false} disableDialog={true} />
                    {links.length > 0 && <div className="links">{links}</div>}
                    <ExtendedProfile recipient={recipient} />
                </Dialog>}
            </>
        );
}
