﻿import { DonationPageState, getFilteredRecipients, OtherAmount, QueryRecipient, Recipient, Term, TipRecipient } from "./state";
import { Action, ActionType } from "./actions";
import { calculateCartAmounts } from "./cart-calculator";

// REDUCERS
const getActiveFilteredRecipients = (state: any) => {
    return getFilteredRecipients(state).filter(r => !r.pausedStatus.paused);
}

export const donationPageReducer = (state: DonationPageState, action: Action): DonationPageState => {
    const updatedState = donationPageReducerCore(state, action);

    // Get recalculated cart amounts
    const cartAmounts = calculateCartAmounts(updatedState);

    // Calculate donation limit
    const donationLimitReached = state.configuration.donationLimit.enabled ? cartAmounts.base > state.configuration.donationLimit.remaining : false;
    const donationTotal = state.configuration.donationLimit.donationTotal;

    const updatedDonationLimit = {
        limitReached: donationLimitReached,
        donatedAmount: donationTotal
    }

    return {
        ...updatedState,
        cartAmounts: cartAmounts,
        donationLimit: updatedDonationLimit
    }
}

const donationPageReducerCore = (state: DonationPageState, action: Action) : DonationPageState => {
    switch (action.type) {
        case ActionType.LoadCurrentUser: {
            // Populate the currentUser and the checkoutForm
            const currentUser = action.currentUser;

            // Get current user's employment status
            const currentUserNotEmployed = currentUser?.employer == "Not Employed" && currentUser?.occupation == "Not Employed";
            const currentUserRetired = currentUser?.employer == "Retired" && currentUser?.occupation == "Retired";
            const employmentStatus = currentUserRetired ? "Retired" :
                                        (currentUserNotEmployed ? "NotEmployed" : "Employed");

            const checkoutForm = {
                ...state.checkoutForm,
                payment: {
                    ...state.checkoutForm.payment,
                    useStoredPaymentAuthorization: currentUser?.paymentAuthorizationSaved
                },
                contact: {
                    firstName: currentUser?.firstName,
                    lastName: currentUser?.lastName,
                    streetAddress: currentUser.address,
                    city: currentUser.city,
                    state: currentUser.state,
                    postalCode: currentUser.postalCode,
                    email: currentUser.email,
                    phoneNumber: currentUser.phoneNumber,
                    textMessagingOptIn: false
                },
                employment: {
                    status: <"Employed" | "Retired" | "NotEmployed">employmentStatus,
                    employer: currentUser.employer,
                    occupation: currentUser.occupation,
                    streetAddress: currentUser.employerAddress,
                    city: currentUser.employerCity,
                    state: currentUser.employerState,
                    postalCode: currentUser.employerPostalCode
                }
            }

            return {
                ...state,
                currentUser: currentUser,
                checkoutForm: checkoutForm
            };
        }
        case ActionType.PageNotFound: {
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    status: "NotFound"
                }
            }
        }
        case ActionType.LoadData: {

            // Figure out the new donor type. Only override with defaultDonorType if the state value
            // is still Default. Otherwise, it has been manually changed and shouldn't be updated with
            // the page default. Also, if still Default, set to Individual if PAC donating isn't enabled.
            let updatedDonorType = state.donorType;
            if (state.donorType == "Default") {
                if (action.data.configuration.pacDonation.enabled) {
                    updatedDonorType = action.data.configuration.pacDonation.defaultDonorType || "Individual";
                }
                else {
                    updatedDonorType = "Individual";
                }
            }

            let dataLoadedState: DonationPageState = {
                ...state,
                configuration: action.data.configuration,
                recipients: action.data.recipients.map((r: any) => {
                    return {
                        ...r,
                        donationAmount: new OtherAmount(r.amount)
                    }
                }),
                donorType: updatedDonorType,
                terms: action.data.terms,
                urlSlug: action.urlSlug
            };

            // Apply default values based on config
            if (dataLoadedState.configuration.slate.featuredRecipients.enabled) {
                dataLoadedState.filters.featured = dataLoadedState.configuration.slate.featuredRecipients.defaultFilter == "Featured";
            }

            // Apply query string recipients
            if (action.queryRecipients.length > 0) {
                dataLoadedState = applyQueryRecipients(dataLoadedState, action.queryRecipients);
            }

            // Add earmarking term
            if (dataLoadedState.configuration.earmarking.enabled) {
                const nonEarmarkedRecipients = dataLoadedState.recipients.filter(r => r.earmarked === false);
                if (nonEarmarkedRecipients.length > 0 && dataLoadedState.terms.every(t => t.id != "Earmarking")) {
                    let termContent = "Contributions to the following candidates are direct donations in accordance with FEC Advisory Opinion 2011-06 and do not constitute earmarked contributions within the meaning of 11 C.F.R. §110.6(b): ";
                    termContent += nonEarmarkedRecipients.map(r => r.displayName).join(", ") + ".";
                    dataLoadedState.terms.push({ id: "Earmarking", content: termContent });
                }
            }

            // Modify contact if PAC donation
            const pacDonation = state.donorType == "FederalPac";
            if (pacDonation) {
                dataLoadedState.checkoutForm.contact.streetAddress = "";
                dataLoadedState.checkoutForm.contact.city = "";
                dataLoadedState.checkoutForm.contact.state = "";
                dataLoadedState.checkoutForm.contact.postalCode = "";
            }

            return dataLoadedState;
        }
        case ActionType.ClearRecipientAmounts: {
            const updatedRecipients = state.recipients.map(r => {
                return { ...r, donationAmount: new OtherAmount(0) };
            });

            return {
                ...state,
                recipients: updatedRecipients
            };
        }
        case ActionType.MaxOut: {
            const filteredRecipientIds = getActiveFilteredRecipients(state).map(r => r.identifier);

            // Check if any recipients have no limit or a limit higher than the threshold
            const threshold = state.configuration.slate.maxOut.thresholdAmount;
            const promptForCustomMax = state.recipients.some(r => filteredRecipientIds.indexOf(r.identifier) >= 0 && (r.contributionLimit == 0 || r.contributionLimit > threshold));
            if (promptForCustomMax && !action.amount) {
                return {
                    ...state,
                    maxOut: {
                        showDialog: true
                    }
                };
            }

            // Apply max out amounts
            const updatedRecipients = maxOut(filteredRecipientIds, state.recipients, action.amount || 0, threshold);

            return {
                ...state,
                recipients: updatedRecipients,
                maxOut: {
                    showDialog: false
                }
            };
        }
        case ActionType.CancelMaxOut: {
            return {
                ...state,
                maxOut: {
                    showDialog: false
                }
            }
        }
        case ActionType.SplitAmount: {
            const filteredRecipientIds = getActiveFilteredRecipients(state).map(r => r.identifier);
            const updatedRecipients = action.weighted
                ? weightedSplitAmount(action.amount, filteredRecipientIds, state.recipients)
                : splitAmount(action.amount, filteredRecipientIds, state.recipients);

            return {
                ...state,
                recipients: updatedRecipients,
            };

        }
        case ActionType.UpdateDonorType:
            // Empty out the stored address if donation is a PAC since the address needs to be
            // the PAC address, not the user's address.
            let updatedContact = { ...state.checkoutForm.contact };
            if (action.donorType == "FederalPac") {
                updatedContact.streetAddress = "";
                updatedContact.city = "";
                updatedContact.state = "";
                updatedContact.postalCode = "";
            }

            return {
                ...state,
                donorType: action.donorType,
                donorTypeUpdated: true,
                checkoutForm: {
                    ...state.checkoutForm,
                    contact: updatedContact
                },
                configuration: {
                    ...state.configuration,
                    status: "Loading"
                }
            };  
        case ActionType.UpdateFilters:
            return {
                ...state,
                filters: action.filters
            };
        case ActionType.UpdateCheckoutForm:
            return {
                ...state,
                checkoutForm: { ...action.checkoutForm }
            };
        case ActionType.UpdateSubscriptionStatus:
            return {
                ...state,
                createSubscription: action.subscribe
            };
        case ActionType.UpdateApiErrors:
            return {
                ...state,
                apiErrors: action.apiErrors
            };
        case ActionType.UpdateRecipientAmount: {
            const updatedRecipients = state.recipients.map(r => {
                if (r.identifier == action.identifier) {
                    return { ...r, donationAmount: action.amount };
                } else {
                    return r;
                }
            });

            return {
                ...state,
                recipients: updatedRecipients
            };
        }
        case ActionType.UpdateSingleRecipientAmount: {
            const updatedRecipients = state.recipients.map(r => {
                if (r.identifier == action.identifier) {
                    return { ...r, amount: action.amount };
                } else {
                    return { ...r, amount: 0 };
                }
            });

            return {
                ...state,
                recipients: updatedRecipients
            };
        }
        case ActionType.UpdateRecipientAttribution: {
            const updatedRecipients = state.recipients.map(r => {
                if (r.identifier == action.identifier) {
                    return { ...r, attribution: action.attribution };
                } else {
                    return r;
                }
            });

            return {
                ...state,
                recipients: updatedRecipients
            };
        }
        case ActionType.ShowRecipientDialog: {
            return {
                ...state,
                recipientDialog: {
                    ...state.recipientDialog,
                    show: true,
                    recipientId: action.recipientId
                }
            };
        }
        case ActionType.HideRecipientDialog: {
            return {
                ...state,
                recipientDialog: {
                    ...state.recipientDialog,
                    show: false,
                    recipientId: null
                }
            };
        }
        case ActionType.ShowProcessingIndicator: {
            return {
                ...state,
                processingIndicator: {
                    ...state.processingIndicator,
                    show: true,
                    message: action.message
                }
            };
        }
        case ActionType.HideProcessingIndicator: {
            return {
                ...state,
                processingIndicator: {
                    ...state.processingIndicator,
                    show: false,
                    message: ""
                }
            };
        }
        default:
            console.error("Unknown action:", action);
            return state;
    }
};

const applyQueryRecipients = (state: DonationPageState, queryRecipients: QueryRecipient[]) => {
    const updatedConfiguration = {
        ...state.configuration,
        slate: {
            ...state.configuration.slate,
            featuredRecipients: {
                ...state.configuration.slate.featuredRecipients,
                enabled: false
            }
        }
    };

    const updatedFilters = {
        ...state.filters,
        featured: false
    }

    const updatedRecipients =
        state.recipients
            .filter(r => queryRecipients.some(qr => qr.identifier === r.identifier))
            .map(r => {
                const queryAmount = queryRecipients
                    .find(qr => qr.identifier === r.identifier)
                    ?.amount || 0;

                const baseAmount = r.contributionLimit ? Math.min(r.contributionLimit, queryAmount) : queryAmount;
                const normalizedAmount = Math.max(0, baseAmount);

                return {
                    ...r,
                    donationAmount: new OtherAmount(normalizedAmount)
                }
            });

    const updatedTerms = filterTerms(state.terms, updatedRecipients, <TipRecipient>updatedConfiguration.checkout.tip.recipient);

    return {
        ...state,
        configuration: updatedConfiguration,
        filters: updatedFilters,
        recipients: updatedRecipients,
        terms: updatedTerms
    }
}

const filterTerms = (terms: Term[], recipients: Recipient[], tipRecipient?: TipRecipient) : Term[] => {
    let recipientTermIds = recipients.filter(r => r.donationAmount && r.donationAmount.amount).flatMap(r => r.termIds);
    if (tipRecipient) {
        const tipTermIds = tipRecipient.termIds;
        recipientTermIds = recipientTermIds.concat(tipTermIds);
    }

    return terms.filter(t => recipientTermIds.indexOf(t.id) >= 0);
}

// Maxes out all filtered recipients, leaving the others as-is and returning
// all recipients
const maxOut = (filteredRecipientIds: string[], recipients: Recipient[], inputAmount: number, thresholdAmount: number) => {

    console.log("maxOut", filteredRecipientIds);
    console.log("inputAmount", inputAmount);
    console.log("thresholdAmount", thresholdAmount);

    // Make a copy and zero-out all amounts in the filteredRecipients
    const recipientsCopy = recipients.map(r => {
        if (filteredRecipientIds.includes(r.identifier)) {
            return { ...r, donationAmount: new OtherAmount(0) };
        } else {
            return { ...r };
        }
    });

    return recipientsCopy.map((recipient: Recipient) => {
        if (filteredRecipientIds.includes(recipient.identifier)) {
            const contributionLimit = recipient.contributionLimit;
            if (contributionLimit == 0) { // If recipient doesn't have a contribution limit, set their amount to the input amount
                recipient.donationAmount = new OtherAmount(inputAmount);
            }
            else if (contributionLimit > thresholdAmount) { // If recipient's limit is above the threshold, set their amount to the input amount, not to exceed the limit
                if (inputAmount > contributionLimit) {
                    recipient.donationAmount = new OtherAmount(contributionLimit);
                } else {
                    recipient.donationAmount = new OtherAmount(inputAmount);
                }
            }
            else { // Set the amount to the limit of the recipient
                recipient.donationAmount = new OtherAmount(contributionLimit);
            }
        }

        return recipient;
    });
}

// Splits an amount between the given recipients
const splitAmount = (amount: number, filteredRecipientIds: string[], recipients: Recipient[]) => {
    if (!amount) {
        return recipients;
    }

    // Make a copy and zero-out all amounts in the filteredRecipients
    const recipientsCopy = recipients.map(r => {
        if (filteredRecipientIds.includes(r.identifier)) {
            return { ...r, donationAmount: new OtherAmount(0) };
        } else {
            return { ...r };
        }
    });

    const splitEvenlyToRecipients = function (recipients: Recipient[], total: number) {
        var divider = recipients.length;
        recipients.map(recipient => {
            recipient.donationAmount = new OtherAmount(0);
        });

        // $1 minimum amount
        var minimumAmount = 100;
        while (divider > 0 && total >= minimumAmount) {
          var dividedAmount = Math.max(minimumAmount, Math.round(total / divider));
          var recipient = recipients[divider - 1];

          recipient.donationAmount = new OtherAmount(dividedAmount / 100);
          divider--;
          total -= dividedAmount;
        }
    }

    var eligibleRecipients =  recipientsCopy.filter(recipient => {
        // Can't split on recipients with goals achieved or contribution limit reached
        if (recipient.goal?.achieved) {
            return false;
        }

        // Don't include non-filtered recipients
        if (!filteredRecipientIds.includes(recipient.identifier)) {
            return false;
        }

        return true;
    });

    var totalAmount = amount * 100;
    var averageAmount = Math.round(totalAmount / eligibleRecipients.length);
    var minimumContributionLimit = 100000000;

    // Calculate limits
    eligibleRecipients.map((recipient: Recipient) => {
        if (recipient.contributionLimit > 0 && recipient.contributionLimit * 100 < minimumContributionLimit) {
            minimumContributionLimit = recipient.contributionLimit * 100;
        }
    });

    if (minimumContributionLimit > averageAmount) {
        // Split amount evenly when the minimum limit is above the evenly divided amount
        splitEvenlyToRecipients(eligibleRecipients, totalAmount);
    } else {
        // Max all recipients with contribution limit below average amount, recalculating average
        let remainingTotal = totalAmount;
        let remainingRecipients = eligibleRecipients;
        let remainingRecipientsLength = 0;

        while (remainingRecipients.length != remainingRecipientsLength) {
            remainingRecipientsLength = remainingRecipients.length;
            let currentRecipients = remainingRecipients;
            remainingRecipients = [];

            currentRecipients.map(recipient => {
                if (recipient.contributionLimit * 100 < averageAmount && recipient.contributionLimit > 0) {
                    recipient.donationAmount = new OtherAmount(recipient.contributionLimit);
                    remainingTotal -= recipient.contributionLimit * 100;
                } else {
                    remainingRecipients.push(recipient);
                }
            });

            // Recalculate average amount and repeat until the remaining recipients list doesn't change
            averageAmount = remainingTotal / remainingRecipients.length;
        }

        // Distribute the remaining amount evenly among the remaining recipients
        splitEvenlyToRecipients(remainingRecipients, remainingTotal);
    }

    return recipientsCopy;
}

// Splits an amount between the given recipients
const weightedSplitAmount = (amount: number, filteredRecipientIds: string[], recipients: Recipient[]) => {
    if (!amount) {
        return recipients;
    }

    // Make a copy and zero-out all amounts in the filteredRecipients
    const workingRecipients = recipients.map(r => {
        if (filteredRecipientIds.includes(r.identifier)) {
            return { ...r, donationAmount: new OtherAmount(0) };
        } else {
            return { ...r };
        }
    });

    const getEligibleRecipients = (recipients: Recipient[])  =>{
        return recipients.filter(recipient => {
            // Recipients with weights of 0 are omitted
            if (recipient.weight == 0) {
                return false;
            }

            // Can't split on recipients with goals achieved or contribution limit reached
            if (recipient.goal?.achieved) {
                return false;
            }

            // Don't include non-filtered recipients
            if (!filteredRecipientIds.includes(recipient.identifier)) {
                return false;
            }

            if (recipient.donationAmount.amount >= recipient.contributionLimit && recipient.contributionLimit != 0) {
                return false;
            }

            return true;
        }).sort((r1, r2) => r2.weight - r1.weight);
    }

    // Initial conditions
    const splitAmount = amount;
    var remainingAmountToSplit = amount;
    var eligibleRecipients = getEligibleRecipients(workingRecipients);
    var previousAmountRemaining = remainingAmountToSplit;

    // Loop while there is a remaining amount to split
    while (remainingAmountToSplit > 0) {

        // Get the total weights for the current round of splitting
        const totalWeights = eligibleRecipients.reduce((partialSum, r) => partialSum + r.weight, 0);

        eligibleRecipients.forEach(r => {
            const recipientCopy = <Recipient>workingRecipients.find(rc => rc.identifier == r.identifier);
            const weightedRatio = r.weight / totalWeights;

            var weightedCents = Math.round(weightedRatio * remainingAmountToSplit * 100);

            if (Math.round(weightedCents) == 0) {
                weightedCents = 1;
            }

            const weightedAmount = weightedCents/100;

            var availableToRecipient = 0;
            if (recipientCopy.contributionLimit == 0) {
                availableToRecipient = weightedAmount;
            } else {
                const remainingBelowLimit = recipientCopy.contributionLimit - recipientCopy.donationAmount.amount;
                availableToRecipient = Math.min(remainingBelowLimit, weightedAmount);
            }

            // Check currently assigned total to make sure we don't go over due to rounding/division
            const totalAssigned = Math.round(100 * workingRecipients.reduce((partialSum, r) => partialSum + r.donationAmount.amount, 0)) / 100;
            if (totalAssigned + availableToRecipient > splitAmount) {
                availableToRecipient = splitAmount - totalAssigned;
            }

            // Assign and then round to prevent floating point issues
            const availableAmount = recipientCopy.donationAmount.amount + availableToRecipient;
            recipientCopy.donationAmount = new OtherAmount(Math.round(availableAmount * 100) / 100);
        });

        const remainder = splitAmount - workingRecipients.reduce((partialSum, r) => partialSum + r.donationAmount.amount, 0);
        remainingAmountToSplit = remainder;

        if (remainingAmountToSplit == 0 || previousAmountRemaining == remainingAmountToSplit) {
            break;
        } else {
            // Refilter the eligible recipients now that their amounts are updated
            previousAmountRemaining = remainingAmountToSplit;
            eligibleRecipients = getEligibleRecipients(workingRecipients);
        }
    }

    return workingRecipients;
}